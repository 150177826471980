/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetValidationPlanIdByRecordIdResponse,
    GetValidationPlanIdByRecordIdResponseFromJSON,
    GetValidationPlanIdByRecordIdResponseToJSON,
    PCSBatchValidationPlanStatusesResponse,
    PCSBatchValidationPlanStatusesResponseFromJSON,
    PCSBatchValidationPlanStatusesResponseToJSON,
} from '../models';

export interface PcsValidationOnDemandPcsV1ValidationStatusesGetRequest {
    validationPlanIds: Array<string>;
}

export interface PcsValidationOnDemandPcsValidationV1RecordIdGetRequest {
    recordId: string;
}

export interface PcsValidationOnDemandWorkflowV1ValidationStatusesGetRequest {
    workflowIds: Array<string>;
}

/**
 * 
 */
export class PcsApi extends runtime.BaseAPI {

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/pcs/validation/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandPcsV1ValidationStatusesGetRaw(requestParameters: PcsValidationOnDemandPcsV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PCSBatchValidationPlanStatusesResponse>> {
        if (requestParameters.validationPlanIds === null || requestParameters.validationPlanIds === undefined) {
            throw new runtime.RequiredError('validationPlanIds','Required parameter requestParameters.validationPlanIds was null or undefined when calling pcsValidationOnDemandPcsV1ValidationStatusesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.validationPlanIds) {
            queryParameters['validationPlanIds'] = requestParameters.validationPlanIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/v1/validation/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PCSBatchValidationPlanStatusesResponseFromJSON(jsonValue));
    }

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/pcs/validation/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandPcsV1ValidationStatusesGet(requestParameters: PcsValidationOnDemandPcsV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<PCSBatchValidationPlanStatusesResponse> {
        const response = await this.pcsValidationOnDemandPcsV1ValidationStatusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a validation plan id by corresponding record id
     * Get a validation plan id by corresponding record id
     */
    async pcsValidationOnDemandPcsValidationV1RecordIdGetRaw(requestParameters: PcsValidationOnDemandPcsValidationV1RecordIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetValidationPlanIdByRecordIdResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling pcsValidationOnDemandPcsValidationV1RecordIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/validation/v1/{recordId}`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetValidationPlanIdByRecordIdResponseFromJSON(jsonValue));
    }

    /**
     * Get a validation plan id by corresponding record id
     * Get a validation plan id by corresponding record id
     */
    async pcsValidationOnDemandPcsValidationV1RecordIdGet(requestParameters: PcsValidationOnDemandPcsValidationV1RecordIdGetRequest, initOverrides?: RequestInit): Promise<GetValidationPlanIdByRecordIdResponse> {
        const response = await this.pcsValidationOnDemandPcsValidationV1RecordIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Given a list of workflow ids, return the associated statuses for PCS. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET /pcs/validation-on-demand/workflow/v1/validation/statuses?workflowIds={workflowIds}. example - /pcs/validation-on-demand/workflow/v1/validation/statuses?workflowIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandWorkflowV1ValidationStatusesGetRaw(requestParameters: PcsValidationOnDemandWorkflowV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PCSBatchValidationPlanStatusesResponse>> {
        if (requestParameters.workflowIds === null || requestParameters.workflowIds === undefined) {
            throw new runtime.RequiredError('workflowIds','Required parameter requestParameters.workflowIds was null or undefined when calling pcsValidationOnDemandWorkflowV1ValidationStatusesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.workflowIds) {
            queryParameters['workflowIds'] = requestParameters.workflowIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/workflow/v1/validation/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PCSBatchValidationPlanStatusesResponseFromJSON(jsonValue));
    }

    /**
     * Given a list of workflow ids, return the associated statuses for PCS. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET /pcs/validation-on-demand/workflow/v1/validation/statuses?workflowIds={workflowIds}. example - /pcs/validation-on-demand/workflow/v1/validation/statuses?workflowIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandWorkflowV1ValidationStatusesGet(requestParameters: PcsValidationOnDemandWorkflowV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<PCSBatchValidationPlanStatusesResponse> {
        const response = await this.pcsValidationOnDemandWorkflowV1ValidationStatusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
