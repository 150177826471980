/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogbooksValidationRequest
 */
export interface LogbooksValidationRequest {
    /**
     * Record name originally generated in the UI
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    name: string;
    /**
     * Currently the description of workflow received from the UI, provided from workflow.description for time being
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    instructions: string;
    /**
     * Workflow ID VOD will validate against, equivalent of childWorkflowId in BFF contract
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    templateId: string;
    /**
     * Will be used as witness user for steps allowing any user to witness
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    defaultWitnessUserId?: string;
    /**
     * Will be used as verifier user for steps allowing any user to witness
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    defaultVerifyUserId?: string;
    /**
     * User ID of the user who initiated the validation run from the UI
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    owner: string;
    /**
     * array of assignee ids
     * @type {Array<string>}
     * @memberof LogbooksValidationRequest
     */
    assignees?: Array<string>;
    /**
     * Reviewer Id
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    reviewer?: string;
    /**
     * Number of rows in the created validation logbooks record
     * @type {number}
     * @memberof LogbooksValidationRequest
     */
    numberOfRows?: number;
    /**
     * optional, array of reviewers if there is more than one
     * @type {Array<string>}
     * @memberof LogbooksValidationRequest
     */
    reviewers?: Array<string>;
    /**
     * in addition to user id, group id could be supplied here
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    ownerGroup?: string;
    /**
     * in addition to user ids, group ids could be supplied here
     * @type {Array<string>}
     * @memberof LogbooksValidationRequest
     */
    assigneeGroups?: Array<string>;
    /**
     * in addition to user ids, group ids could be supplied here
     * @type {Array<string>}
     * @memberof LogbooksValidationRequest
     */
    reviewerGroups?: Array<string>;
}

export function LogbooksValidationRequestFromJSON(json: any): LogbooksValidationRequest {
    return LogbooksValidationRequestFromJSONTyped(json, false);
}

export function LogbooksValidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogbooksValidationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'instructions': json['instructions'],
        'templateId': json['templateId'],
        'defaultWitnessUserId': !exists(json, 'defaultWitnessUserId') ? undefined : json['defaultWitnessUserId'],
        'defaultVerifyUserId': !exists(json, 'defaultVerifyUserId') ? undefined : json['defaultVerifyUserId'],
        'owner': json['owner'],
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] as Array<string>),
        'reviewer': !exists(json, 'reviewer') ? undefined : json['reviewer'],
        'numberOfRows': !exists(json, 'numberOfRows') ? undefined : json['numberOfRows'],
        'reviewers': !exists(json, 'reviewers') ? undefined : (json['reviewers'] as Array<string>),
        'ownerGroup': !exists(json, 'ownerGroup') ? undefined : json['ownerGroup'],
        'assigneeGroups': !exists(json, 'assigneeGroups') ? undefined : (json['assigneeGroups'] as Array<string>),
        'reviewerGroups': !exists(json, 'reviewerGroups') ? undefined : (json['reviewerGroups'] as Array<string>),
    };
}

export function LogbooksValidationRequestToJSON(value?: LogbooksValidationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'instructions': value.instructions,
        'templateId': value.templateId,
        'defaultWitnessUserId': value.defaultWitnessUserId,
        'defaultVerifyUserId': value.defaultVerifyUserId,
        'owner': value.owner,
        'assignees': value.assignees === undefined ? undefined : (value.assignees as Array<string>),
        'reviewer': value.reviewer,
        'numberOfRows': value.numberOfRows,
        'reviewers': value.reviewers === undefined ? undefined : (value.reviewers as Array<string>),
        'ownerGroup': value.ownerGroup,
        'assigneeGroups': value.assigneeGroups === undefined ? undefined : (value.assigneeGroups as Array<string>),
        'reviewerGroups': value.reviewerGroups === undefined ? undefined : (value.reviewerGroups as Array<string>),
    };
}

